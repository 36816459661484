import React from "react";

//  Styling
import "../../../../styles/LexResponseCard.scss";

const LexResponseCard = ({ content, responeTxt, onClick }) => {
  const handleClick = () => {
    onClick(responeTxt, content);
  };
  return (
    <div className="responseButtonContainer">
      <div className="responseButton" onClick={handleClick}>
        {content}
      </div>
    </div>
  );
};

export default LexResponseCard;
