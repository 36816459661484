import React, { useState, useEffect } from "react";

// AWS SDK
import AWS from "aws-sdk";
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  //   CognitoUserSession,
} from "amazon-cognito-identity-js";

AWS.config.region = process.env.REACT_APP_AWS_REGION; //  Set AWS region which is obtained from environmental variables
const cognitoPoolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL,
  ClientId: process.env.REACT_APP_CLIENT_ID,
}; // Create JS object for UserPool

const cognitoUserPool = new CognitoUserPool(cognitoPoolData);

const VAssistantContext = React.createContext({
  signIn: async (email, password, newPassword = null) => {},
  signOut: () => {},
  user: null,
  newPassRequired: false,
  requiredAttributes: [],
  isLoggedIn: false,
  AWS: AWS,
});

export const VAssistantContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [userAttribs, setUserAttribs] = useState(null);
  const [newPassRequired, setNewPassRequired] = useState(false);
  const [requiredAttributes, setRequiredAttributes] = useState([]);

  useEffect(() => {
    const storedIdToken = localStorage.getItem("idToken");

    if (storedIdToken) {
      // Update AWS credentials with the stored idToken
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL,
        Logins: {
          [`cognito-idp.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL}`]:
            storedIdToken,
        },
      });

      // Refresh AWS credentials
      AWS.config.credentials.get((err) => {
        if (err) {
          console.error("Error refreshing AWS credentials:", err);
          setIsLoggedIn(false);
        } else {
          setIsLoggedIn(true); // Set user as logged in
        }
      });
    }

    return () => {
      // Clean up the listener when the component unmounts
    };
  }, []);

  //   const checkUser = async () => {
  //     const storedAccessToken = Cookies.get("accessToken");
  //     const storedIdToken = Cookies.get("idToken");
  //     const storedRefreshToken = Cookies.get("refreshToken");

  //     if (storedAccessToken && storedIdToken && storedRefreshToken) {
  //       const sessionData = {
  //         accessToken: storedAccessToken,
  //         idToken: storedIdToken,
  //         refreshToken: storedRefreshToken,
  //       };

  //       try {
  //         console.log("checkUser", storedIdToken);
  //         AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //           IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL,
  //           Logins: {
  //             [`cognito-idp.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${[
  //               process.env.REACT_APP_USER_POOL,
  //             ]}`]: storedIdToken,
  //           },
  //         });

  //         const userSession = new CognitoUserSession(sessionData);

  //         if (userSession.isValid()) {
  //           const userData = await getUserDataFromSession(userSession);
  //           setUser(userData);
  //         } else {
  //           console.log("Session is not valid");
  //         }

  //         setIsLoggedIn(true);
  //       } catch (error) {
  //         alert(error.message || JSON.stringify(error));
  //       }
  //     }
  //   };

  //   const getUserDataFromSession = async (userSession) => {
  //     try {
  //       const cognitoUser = cognitoUserPool.getCurrentUser();

  //       if (!cognitoUser) {
  //         throw new Error("User not found");
  //       }

  //       //  Use the CognitoUser object to getch user attributes
  //       const userData = await new Promise((resolve, reject) => {
  //         cognitoUser.getSession((err, session) => {
  //           if (err) {
  //             reject(err);
  //             return;
  //           }

  //           const attributes = [];

  //           cognitoUser.getUserAttributes((attributeErr, attributesResult) => {
  //             if (attributeErr) {
  //               reject(attributeErr);
  //               return;
  //             }

  //             attributesResult.forEach((attribute) => {
  //               attributes.push({
  //                 Name: attribute.getName(),
  //                 Value: attribute.getValue(),
  //               });
  //             });

  //             resolve({
  //               username: cognitoUser.getUsername(),
  //               attributes: attributes,
  //             });
  //           });
  //         });
  //       });

  //       return userData;
  //     } catch (error) {
  //       throw new Error(
  //         "Error fetching user data from session: " + error.message
  //       );
  //     }
  //   };

  const signIn = async (email, password, newPassword, newAttributes) => {
    try {
      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: cognitoUserPool,
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: async (result) => {
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL,
            Logins: {
              [`cognito-idp.${
                process.env.REACT_APP_AWS_REGION
              }.amazonaws.com/${[process.env.REACT_APP_USER_POOL]}`]: result
                .getIdToken()
                .getJwtToken(),
            },
          });

          // Refresh AWS credentials
          await AWS.config.credentials.get((err) => {
            if (err) {
              console.error("Error refreshing AWS credentials:", err);
              setIsLoggedIn(false);
            } else {
              setIsLoggedIn(true); // Set user as logged in
            }
          });

          const accessToken = result.getAccessToken().getJwtToken();
          localStorage.setItem("accessToken", accessToken);
          // Cookies.set("accessToken", accessToken, {
          //     secure: true,
          //     sameSite: "strict",
          //     expires: new Date(result.getAccessToken().payload.exp * 1000),
          // });

          const idToken = result.getIdToken().getJwtToken();
          localStorage.setItem("idToken", idToken);
          // Cookies.set("idToken", idToken, {
          //     secure: true,
          //     sameSite: "strict",
          //     expires: new Date(result.getIdToken().payload.exp * 1000),
          // });
        },

        onFailure: (err) => {
          alert(err.message || JSON.stringify(err));
        },

        newPasswordRequired: (userAttributes, requiredAttributes) => {
          if (!newPassword) {
            delete userAttributes.email;
            setUserAttribs(userAttributes);
            setNewPassRequired(true);
            setIsLoggedIn(false);
            setRequiredAttributes(requiredAttributes);
          } else {
            cognitoUser.completeNewPasswordChallenge(
              newPassword,
              newAttributes,
              {
                onSuccess: async (result) => {
                  setNewPassRequired(false);
                  setIsLoggedIn(true);
                  setUser(cognitoUser);
                  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL,
                    Logins: {
                      [`cognito-idp.${
                        process.env.REACT_APP_AWS_REGION
                      }.amazonaws.com/${[process.env.REACT_APP_USER_POOL]}`]:
                        result.getIdToken().getJwtToken(),
                    },
                  });

                  // Refresh AWS credentials
                  await AWS.config.credentials.get((err) => {
                    if (err) {
                      console.error("Error refreshing AWS credentials:", err);
                      setIsLoggedIn(false);
                    } else {
                      setIsLoggedIn(true); // Set user as logged in
                    }
                  });

                  const accessToken = result.getAccessToken().getJwtToken();
                  localStorage.setItem("accessToken", accessToken);
                  // Cookies.set("accessToken", accessToken, {
                  //     secure: true,
                  //     sameSite: "strict",
                  //     expires: new Date(result.getAccessToken().payload.exp * 1000),
                  // });

                  const idToken = result.getIdToken().getJwtToken();
                  localStorage.setItem("idToken", idToken);
                  // Cookies.set("idToken", idToken, {
                  //     secure: true,
                  //     sameSite: "strict",
                  //     expires: new Date(result.getIdToken().payload.exp * 1000),
                  // });
                },

                onFailure: (err) => {
                  alert(err.message || JSON.stringify(err));
                },
              }
            );
          }
        },
      });
    } catch (error) {
      alert(error.message || JSON.stringify(error));
    }
  };

  const signOut = () => {
    try {
      const cognitoUser = cognitoUserPool.getCurrentUser();
      if (cognitoUser !== null) {
        cognitoUser.signOut();
      }

      setIsLoggedIn(false);
      setUser(null);
      setNewPassRequired(false);
      setUserAttribs(null);
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("idToken");
    } catch (error) {
      alert(error.message || JSON.stringify(error));
    }
  };

  return (
    <VAssistantContext.Provider
      value={{
        signIn: signIn,
        signOut: signOut,
        user: user,
        newPassRequired: newPassRequired,
        isLoggedIn: isLoggedIn,
        requiredAttributes: requiredAttributes,
        AWS: AWS,
      }}
    >
      {props.children}
    </VAssistantContext.Provider>
  );
};

export { AWS };
export default VAssistantContext;
