import React, { useContext } from "react";

//  Styling
import "../../styles/SignOutButton.scss";

//  Context
import VAssistantContext from "../../../store/context";

//  React components

//  Assets

export default function SignOutButton(props) {
  const {signOut} = useContext(VAssistantContext);
  
  return (
    <div className="signOutContainer">
      <button className="signOutbtn" type="button" onClick={signOut}>
        LogOut
      </button>
    </div>
  );
}
