import React, { useContext, useState } from "react";

//  uuid
import { v4 as uuidv4 } from "uuid";

//  Styling
import "../../../styles/VirtualAssistantWidget.scss";

//  Components
import WidgetHeader from "./WidgetForm/WidgetHeader";
import WidgetMessageHistory from "./WidgetForm/WidgetMessageHistory";
import WidgetInput from "./WidgetForm/WidgetInput";
import ButtonContainer from "./ButtonContainer";

//  Context
import VAssistantContext from "../../../../store/context";

//  AWS SDK
// import AWS from 'aws-sdk';

let userLanguageCode = null;

export default function VirtualAssistantWidget(props) {
  const { AWS } = useContext(VAssistantContext);

  const comprehendClient = new AWS.Comprehend();
  const translateClient = new AWS.Translate();
  const lexV2Client = new AWS.LexRuntimeV2();

  //    Used for button state
  const [isOpened, setIsOpened] = useState(false);
  //    Assistant engagement
  const [isEngaged, setIsEngaged] = useState(false);

  //    Chat toggler handler
  const assistantTogglerHandler = async () => {
    if (isEngaged) {
      await lexV2Client
        .deleteSession({
          botId: process.env.REACT_APP_LEX_ID,
          botAliasId: process.env.REACT_APP_LEX_ALIAS_ID,
          localeId: process.env.REACT_APP_LEX_LOCALE,
          sessionId: lexSessionID,
        })
        .promise();
      setLexSessionID(uuidv4());
      setIsEngaged(false);
    }
    setIsOpened(!isOpened);
    setChatMessages([
      { message: greetingMessage, sender: "bot", type: "PlainText" },
    ]);
    userLanguageCode = null;
  };

  //  Generate sessionID
  const [lexSessionID, setLexSessionID] = useState(uuidv4());

  const greetingMessage =
    "Greetings, I'm your virtual assistant.\nI can help you with booking tickets in Germany or providing information about an existing booking.\nYou can communicate with me in your native language if you wish.";

  // Greeting on english
  const [chatMessages, setChatMessages] = useState([
    { message: greetingMessage, sender: "bot", type: "PlainText" },
  ]);

  //  State for user input
  const [userMessage, setUserMessage] = useState("");

  const inputTextChangeHandler = (event) => {
    setUserMessage(event.target.value);
  };

  const [waitingLex, setWaitingLex] = useState(false);

  const submitHandler = async (event) => {
    event.preventDefault();
    if (userMessage === "" || waitingLex) {
      return;
    }

    try {
      let translatedMessage = userMessage;
      setWaitingLex(true);

      //  Use Comprehend to detect language on first message
      if (!userLanguageCode) {
        const languageData = await comprehendClient
          .detectDominantLanguage({ Text: userMessage })
          .promise();
        userLanguageCode = languageData.Languages[0].LanguageCode;
      }

      //  If the userLanguage is not LEX language, tranlate message
      if (userLanguageCode !== process.env.REACT_APP_LEX_LANGUAGE) {
        const translationData = await translateClient
          .translateText({
            SourceLanguageCode: userLanguageCode,
            TargetLanguageCode: process.env.REACT_APP_LEX_LANGUAGE,
            Text: translatedMessage,
          })
          .promise();
        translatedMessage = translationData.TranslatedText;
      }

      // Populate chat History
      setChatMessages((prev) => [
        ...prev,
        { message: userMessage, sender: "user", type: "PlainText" },
      ]);
      setUserMessage("");

      //  Update engagement state
      if (!isEngaged) {
        setIsEngaged(true);
      }

      //  Contact Lex
      const lexResponse = await lexV2Client
        .recognizeText({
          botId: process.env.REACT_APP_LEX_ID,
          botAliasId: process.env.REACT_APP_LEX_ALIAS_ID,
          localeId: process.env.REACT_APP_LEX_LOCALE,
          sessionId: lexSessionID,
          text: translatedMessage,
        })
        .promise();

      //  Create array of vAssistant messages
      // eslint-disable-next-line array-callback-return
      const vAssistantMessages = lexResponse.messages.map((msg) => {
        if (msg.contentType === "PlainText") {
          return { message: msg.content, sender: "bot", type: msg.contentType };
        } else if (msg.contentType === "ImageResponseCard") {
          return {
            message: msg.imageResponseCard.title,
            sender: "bot",
            type: msg.contentType,
            buttons: msg.imageResponseCard.buttons.map((button) => {
              // console.log({ message: button.text, returnValue: button.value });
              return { message: button.text, returnValue: button.value };
            }),
          };
        }
      });

      // console.log(vAssistantMessages)
      //  If user lang !== LexLang, translate vAssistantMessages back to the original language
      if (userLanguageCode !== process.env.REACT_APP_LEX_LANGUAGE) {
        for (const msg of vAssistantMessages) {
          if (msg.type === "PlainText") {
            const trnsMsg = await translateClient
              .translateText({
                SourceLanguageCode: process.env.REACT_APP_LEX_LANGUAGE,
                TargetLanguageCode: userLanguageCode,
                Text: msg.message,
              })
              .promise();
            msg.message = trnsMsg.TranslatedText;
          } else if (msg.type === "ImageResponseCard") {
            const trnsTitle = await translateClient.translateText({SourceLanguageCode: process.env.REACT_APP_LEX_LANGUAGE,TargetLanguageCode: userLanguageCode,Text: msg.message,}).promise();
            msg.message = trnsTitle.TranslatedText;
            // eslint-disable-next-line no-loop-func
            for (const btn of msg.buttons) {
              const trnsMsg = await translateClient.translateText({SourceLanguageCode: process.env.REACT_APP_LEX_LANGUAGE,TargetLanguageCode: userLanguageCode,Text: btn.message,}).promise();
              btn.message = trnsMsg.TranslatedText
            }
          }
        }
      }

      // Populate chat History
      setChatMessages((prev) => [...prev, ...vAssistantMessages]);
      setWaitingLex(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleResponseCardClick = async (srcTxt, trnsTxt) => {
    try {
      // let translatedMessage = message;
      setWaitingLex(true);
      setChatMessages((prev) => [
        ...prev,
        { message: trnsTxt, sender: "user", type: "PlainText" },
      ]);

      //  Update engagement state
      if (!isEngaged) {
        setIsEngaged(true);
      }

      //  Contact Lex
      const lexResponse = await lexV2Client
        .recognizeText({
          botId: process.env.REACT_APP_LEX_ID,
          botAliasId: process.env.REACT_APP_LEX_ALIAS_ID,
          localeId: process.env.REACT_APP_LEX_LOCALE,
          sessionId: lexSessionID,
          text: srcTxt,
        })
        .promise();

      //  Create array of vAssistant messages
      // eslint-disable-next-line array-callback-return
      const vAssistantMessages = lexResponse.messages.map((msg) => {
        if (msg.contentType === "PlainText") {
          return { message: msg.content, sender: "bot", type: msg.contentType };
        } else if (msg.contentType === "ImageResponseCard") {
          return {
            message: msg.imageResponseCard.title,
            sender: "bot",
            type: msg.contentType,
            buttons: msg.imageResponseCard.buttons.map((button) => {
              return { message: button.text, returnValue: button.value };
            }),
          };
        }
      });

      //  If user lang !== LexLang, translate vAssistantMessages back to the original language
      if (userLanguageCode !== process.env.REACT_APP_LEX_LANGUAGE) {
        for (const msg of vAssistantMessages) {
          if (msg.type === "PlainText") {
            const trnsMsg = await translateClient
              .translateText({
                SourceLanguageCode: process.env.REACT_APP_LEX_LANGUAGE,
                TargetLanguageCode: userLanguageCode,
                Text: msg.message,
              })
              .promise();
            msg.message = trnsMsg.TranslatedText;
          } else if (msg.type === "ImageResponseCard") {
            const trnsTitle = await translateClient.translateText({SourceLanguageCode: process.env.REACT_APP_LEX_LANGUAGE,TargetLanguageCode: userLanguageCode,Text: msg.message,}).promise();
            msg.message = trnsTitle.TranslatedText;
            // eslint-disable-next-line no-loop-func
            for (const btn of msg.buttons) {
              const trnsMsg = await translateClient.translateText({SourceLanguageCode: process.env.REACT_APP_LEX_LANGUAGE,TargetLanguageCode: userLanguageCode,Text: btn.message,}).promise();
              btn.message = trnsMsg.TranslatedText
            }
          }
        }
      }

      // Populate chat History
      setChatMessages((prev) => [...prev, ...vAssistantMessages]);

      setWaitingLex(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="widget">
        {isOpened && (
          <div className="vAssistant">
            <WidgetHeader />
            <WidgetMessageHistory
              chatHistory={chatMessages}
              rcResponse={handleResponseCardClick}
            />
            <WidgetInput
              textChangeHandler={inputTextChangeHandler}
              value={userMessage}
              onFormSubmit={submitHandler}
              waiting={waitingLex}
            />
          </div>
        )}
        <ButtonContainer
          isOpened={isOpened}
          onClick={assistantTogglerHandler}
        />
      </div>
    </React.Fragment>
  );
}
