import React from "react";

//  Styling
import '../../../styles/Main.scss';

//  React components

//  Assets
import AKKODISMainWave4kMP4 from "../../../../assets/akkodis/Akkodis_MainWave_4K.mp4";

export default function SectionHeader(props) {
  return (
    <section className="headerSection" id="header">
      <div className="backgr">
        <div className="backgrGrad" />
        <video autoPlay muted loop id="headerVideo">
          <source src={AKKODISMainWave4kMP4} type="video/mp4" />
        </video>
      </div>
      <div className="text">
        <h1>
          <span>Virtual</span> Assistant
        </h1>
      </div>
    </section>
  );
}
