import React from "react";

//  Styling
import '../../../styles/Main.scss';

//  React components

//  Assets

export default function SectionDCSCloud(props) {
  return (
    <section className="contentSection" id="dcs-cloud">
      <div className="backgrDark"> </div>
      <div className="text white">
        <div className="columnContainer">
          <div>
            <h3>DCS-Cloud</h3>
            <ul className="listStyleWhTxt">
              <li>Consulting</li>
              <li>Assessment & Planning</li>
              <li>Design, Architecture and Proof of Concept</li>
              <li>Migration</li>
              <li>Deployment</li>
              <li>Monitoring & Support</li>
              <li>Infrastructure Management</li>
              <li>Compliance, Security and Cost optimization</li>
              <li>Automation</li>
              <li>DevOps</li>
              <li>Internal Cloud academies</li>
            </ul>
          </div>
        </div>
        <div className="listCloud">
          <li>
            <h3>Cloud Support</h3>
            <p>3 Major Cloud supported</p>
          </li>
          <li>
            <h3>100+</h3>
            <p>Projects Implemented</p>
          </li>
          <li>
            <h3>Certification</h3>
            <ul className="listStyleWhTxt">
              <li>AWS Certification</li>
              <li>Azure Certification</li>
              <li>15 Certified Cloud Architects and Admins</li>
            </ul>
          </li>
        </div>
      </div>
    </section>
  );
}
