import React, { useState, useContext } from "react";

//  Styling
import "../../styles/LoginForm.scss";

//  Context
import VAssistantContext from "../../../store/context";

//  React components

//  Assets
import backgrJPG from "../../../assets/backgrounds/backgr.jpg";
import userPNG from "../../../assets/akkodis/user.png";

export default function LoginForm(props) {
  const { signIn, newPassRequired, requiredAttributes } =
    useContext(VAssistantContext);

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [nPassword, setNPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const formSubmitHandler = (event) => {
    event.preventDefault();
    signIn(loginEmail, loginPassword);
  };

  const emailChangeHandler = (event) => {
    setLoginEmail(event.target.value);
  };

  const passwordChangeHandler = (event) => {
    setLoginPassword(event.target.value);
  };

  const nPasswordHandler = (event) => {
    setNPassword(event.target.value);
  };

  const firstNameHandler = (event) => {
    setFirstName(event.target.value);
  };

  const lastNameHandler = (event) => {
    setLastName(event.target.value);
  };

  const initialPasswordChangeHandler = (event) => {
    event.preventDefault();
    signIn(loginEmail, loginPassword, nPassword, {
      family_name: lastName,
      name: firstName,
    });
    setNPassword("");
  };

  return (
    <section className="headerSection" id="header">
      <div className="backgr">
        <div className="backgrGrad" />
        <img src={backgrJPG} alt="" />
      </div>
      <div className="text">
        <h1>
          <span>Virtual</span> Assistant
        </h1>
        <div className="loginFormContainer">
          <div className="loginForm">
            <div className="formHeader">
              <img src={userPNG} height={100} alt="" />
              <span>Login</span>
            </div>
            <div className="formContent">
              {!newPassRequired && (
                <form onSubmit={formSubmitHandler}>
                  <p>
                    E-mail
                    <input
                      type="text"
                      placeholder="E-mail"
                      onChange={emailChangeHandler}
                      value={loginEmail}
                      autoFocus
                    />
                  </p>
                  <p>
                    Password
                    <input
                      type="password"
                      placeholder="Password"
                      onChange={passwordChangeHandler}
                      value={loginPassword}
                    />
                  </p>
                  <p>
                    <button type="submit">Login</button>
                  </p>
                </form>
              )}
              {newPassRequired && (
                <form onSubmit={initialPasswordChangeHandler}>
                  <p>New password</p>
                  <input
                    type="password"
                    placeholder="New password"
                    onChange={nPasswordHandler}
                  />
                  <p></p>
                  {requiredAttributes.map((attribute) => {
                    if (attribute === "family_name") {
                      return (
                        <p>
                          Last Name
                          <input
                            type="text"
                            key={attribute}
                            value={lastName}
                            onChange={lastNameHandler}
                          />
                        </p>
                      );
                    } else if (attribute === "name") {
                      return (
                        <p>
                          First Name
                          <input
                            type="text"
                            key={attribute}
                            value={firstName}
                            onChange={firstNameHandler}
                          />
                        </p>
                      );
                    }
                  })}
                  <button type="submit">Set New Password</button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
