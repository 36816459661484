
//  AWS Assets
import AWSPNG from "../../../../assets/AWS/aws.png";

//  Azure Assets
import AzureJPG from "../../../../assets/Azure/ma.jpg";

//  GCP Assets
import GCPJPG from "../../../../assets/GCP/gc.jpg";

export const CloudSupportContent = {
  tabContainer: [
    {
      id: "pageAWS",
      classes: null,
      span: "Amazon Web Services",
    },
    {
      id: "pageMA",
      classes: null,
      span: "Microsoft Azure",
    },
    {
      id: "pageGC",
      classes: null,
      span: "Google Cloud",
    },
  ],
  tabPage: [
    {
      id: "pageAWS",
      h3: "Amazon Web Services",
      p: [
        `AWS (Amazon Web Services) is a comprehensive, evolving cloud computing platform provided by Amazon that includes a mixture of infrastructure-as-a-service (IaaS), platform-as-a-service (PaaS) and packaged-software-as-a-service (SaaS) offerings. AWS services can offer an organization tools such as compute power, database storage and content delivery services.`,
        `Amazon.com Web Services launched its first web services in 2002 from the internal infrastructure that Amazon.com built to handle its online retail operations. In 2006, it began offering its defining IaaS services. AWS was one of the first companies to introduce a pay-as-you-go cloud computing model that scales to provide users with compute, storage or throughput as needed.`,
        `AWS offers many different tools and solutions for enterprises and software developers that can be used in data centers in up to 190 countries. Groups such as government agencies, education institutions, non-profits and private organizations can use AWS services.`,
      ],
      img: {
        src: AWSPNG,
        alt: "Amazon Web Services",
      },
    },
    {
      id: "pageMA",
      h3: "Microsoft Azure",
      p: [
        `Microsoft Azure, formerly known as Windows Azure, is Microsoft's public cloud computing platform. It provides a broad range of cloud services, including compute, analytics, storage and networking. Users can pick and choose from these services to develop and scale new applications or run existing applications in the public cloud.`,
        `The Azure platform aims to help businesses manage challenges and meet their organizational goals. It offers tools that support all industries -- including e-commerce, finance and a variety of Fortune 500 companies -- and is compatible with open source technologies. This gives users the flexibility to use their preferred tools and technologies. In addition, Azure offers four different forms of cloud computing: infrastructure as a service (IaaS), platform as a service (PaaS), software as a service (SaaS) and serverless functions.`,
        `Microsoft charges for Azure on a pay-as-you-go (PAYG) basis, meaning subscribers receive a bill each month that only charges them for the specific resources and services they have used.`,
      ],
      img: {
        src: AzureJPG,
        alt: "Microsoft Azure",
      },
    },
    {
      id: "pageGC",
      h3: 'Google Cloud',
      p: [
        `Google Cloud is a suite of public cloud computing services offered by Google. The platform includes a range of hosted services for compute, storage and application development that run on Google hardware. Google Cloud services can be accessed by software developers, cloud administrators and other enterprise IT professionals over the public internet or through a dedicated network connection.`,
        `Google Cloud offers services for compute, storage, networking, big data, machine learning and IoT, as well as cloud management, security and developer tools.`,
        `Google continues to add higher-level services, such as those related to big data and machine learning, to its cloud platform. Google big data services include those for data processing and analytics, such as Google BigQuery for SQL-like queries made against multi-terabyte data sets. In addition, Google Cloud Dataflow is a data processing service intended for analytics; extract, transform and load; and real-time computational projects. The platform also includes Google Cloud Dataproc, which offers Apache Spark and Hadoop services for big data processing.`,
      ],
      img: {
        src: GCPJPG,
        alt: "Google cloud",
      },
    },
  ],
};
