import React, { useState } from "react";

//  Styling
import '../../../styles/Main.scss';

//  React components
import { CloudSupportContent } from "./CloudSupport-content";

export default function SectionCloudSupport(props) {
  const [openedPage, setOpenedPage] = useState("pageAWS");

  const changePageHandler = (event) => {
    event.preventDefault();
    setOpenedPage(event.target.id);
  };

  const tabPage = CloudSupportContent.tabPage.filter((page) => {
    return page.id === openedPage;
  });

  return (
    <section className="contentSection" id="cloudSupport">
      <div className="backgrTriangle" style={{ background: "#fff" }} />
      <div className="text">
        <h2>Cloud Support</h2>
        <div className="tabContainer">
          {CloudSupportContent.tabContainer.map((button) => (
          <button id={button.id} key={button.id} className={`tabButton ${button.id === openedPage && "active"}`} onClick={changePageHandler}>
            <span id={button.id}>{button.span}</span>
          </button>
          ))}
        </div>
        <div className="tabPages">
          <div id={tabPage[0].id} className="tabPage" style={{ display: "flex" }} >
            <div className="columnHalf">
              <h3>{tabPage[0].h3}</h3>
              {tabPage[0].p.map((paragraph) => ( <p key={Math.random()}>{paragraph}</p> ))}
            </div>
            <div className="columnHalf">
              <img src={tabPage[0].img.src} alt={tabPage[0].img.alt} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
