import React from "react";

//  Styling

//  React components
import { VAssistantContextProvider } from "./store/context";
import PageContainer from "./components/PageContainer";

//  Authentication context


export default function App() {
  return (
    <React.StrictMode>
      <VAssistantContextProvider>
        <PageContainer />
      </VAssistantContextProvider>
    </React.StrictMode>
  );
}
