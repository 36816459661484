import React, { useEffect, useRef } from "react";

//  Styling
import "../../../../styles/WidgetMessageHistory.scss";

//  React Components
import LexResponseCard from './LexResponseCard'

export default function WidgetMessageHistory(props) {
  const messageEndRef = useRef();

  const scrollToBottom = () => {
    messageEndRef.current.scrollIntoView({behavior: 'smooth',});
  };

  const { chatHistory } = props;

  useEffect(scrollToBottom, [chatHistory]);

  return (
    <div id="messageContainer" className="chatHistory">
      <div className="messageContainer">
        {/* {chatHistory.map((chat, index) => ( <div key={index} className={`chatMessage ${chat.sender}`}> {" "} <p>{chat.message}</p>{" "} </div> ))} */}
        
        {// eslint-disable-next-line array-callback-return
        chatHistory.map((msg, index) => {
          if (msg.type === "PlainText") {
           return ( <div key={index} className={`chatMessage ${msg.sender}`}><p>{msg.message}</p></div> )
          } else if (msg.type === "ImageResponseCard") {
            return (
              <div key={index} className={`chatMessage ${msg.sender}`}>
                <p>{msg.message}</p>
                <div className="BoxresponseButton">
                  {msg.buttons.map((button, idx) => <LexResponseCard key={`rC00${index}00${idx}`} onClick={props.rcResponse} content={button.message} responeTxt={button.returnValue}/>)}
                </div>
              </div>)
          }
        })}
        <div ref={messageEndRef} />
      </div>
    </div>
  );
}