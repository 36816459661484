import React from "react";

//  Styling
import "../../styles/Main.scss";

//  React components
import SectionHeader from "./Sections/SectionHeader";
import SectionDCSCloud from "./Sections/SectionDCSCloud";
import SectionTechSpecs from "./Sections/SectionTechSpecs";
import SectionCloudSupport from "./Sections/SectionCloudSupport";
import SectionYellowBanner from "./Sections/SectionYellowBanner";
import VirtualAssistantWidget from "./vAssistantWidget/VirtualAssistantWidget";

export default function Main(props) {
  const imgs = document.querySelectorAll(".gallery img");
  const fullPage = document.querySelector("#fullpage");

  imgs.forEach((img) => {
    img.addEventListener("click", function () {
      fullPage.style.backgroundImage = "url(" + img.src + ")";
      fullPage.style.display = "block";
    });
  });

  return (
    <main>
      <SectionHeader />
      <SectionDCSCloud />
      <SectionTechSpecs />
      <SectionCloudSupport />
      <SectionYellowBanner />
      <VirtualAssistantWidget />
    </main>
  );
}
