import React from "react";

//  Styling
import '../../../styles/Main.scss';

//  React Components

//  Assets

export default function SectionYellowBanner(props) {
  return (
    <section className="yellowBanner">
      <div className="bannerBackground"> </div>
      <div className="bannerContent">
        <div>
          <h2>
            Start your journey toward a Smarter tomorrow with Akkodis and AWS.
          </h2>
        </div>
        <div className="connectButton">
          <a href="mailto:AkkodisBG.Office@akkodisgroup.com" className="button" target="_blank" rel="noreferrer" >
            Connect with us
          </a>
        </div>
      </div>
    </section>
  );
}
