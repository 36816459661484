import React from "react";

//  Styling
import "../../../../styles/WidgetInput.scss";

//  Assets
import SendButtonWEBP from '../../../../../assets/akkodis/send-button_icon.webp'

export default function WidgetInput(props) {
  const moveFocus = () => {
    document.getElementById("chatTxtInput").focus();
  };

  return (
    <form className="chatInput" onSubmit={props.onFormSubmit}>
      <input id="chatTxtInput" type="text" value={props.value} onChange={props.textChangeHandler} autoFocus />
      <button id="chatSendButton" type="submit" onClick={moveFocus} >
        <img src={SendButtonWEBP} height="28px" alt="" />
      </button>
    </form>
  );
}
