import React, { useContext } from "react";

//  Styling
import "./styles/PageContainer.scss";

//  Authentication context
import VAssistantContext from "../store/context";

//  React components
import Header from "./UI/Header/Header";
import LoginForm from "./UI/LoginForm/LoginForm";
import Main from "./UI/PageBody/Main";
import Footer from "./UI/PageBody/Footer";

//  Assets

export default function PageContainer(props) {
  const { isLoggedIn } = useContext(VAssistantContext);

  return (
    <div className="pageContainer">
      <Header />
      {!isLoggedIn && <LoginForm />}
      {isLoggedIn && (
        <>
          <Main />
          <Footer />
        </>
      )}
    </div>
  );
}
