import React from "react";

//  Styling
import "../../styles/Footer.scss";

//  React components

//  Assets
import AKKODISLogoLightPNG from "../../../assets/akkodis/AKKODIS_Logo_light.png";


export default function Footer(props) {
  return (
    <footer>
      <div className="footer">
        <div className="copyright">
          <a href="https://www.akkodis.com/" target="_blank" rel="noreferrer" alt="AKKODIS" title="AKKODIS" >
            <img src={AKKODISLogoLightPNG} width={192} height={24} alt="" />
          </a>
          <span>
            "The global tech engineering company that"
            <br />" goes beyond possible, to make incredible happen."
          </span>
          <p>© 2023 Akkodis. All rights reserved</p>
        </div>
        <div className="socialMedias">
          <ul className="listMedia">
            <li className="">
              <a className="" href="https://www.linkedin.com/company/akkodis/" target="_blank" rel="noreferrer" title="Join the Akkodis Linkein page (new window)">
                <span aria-hidden className="iconLinkedin" />
              </a>
            </li>
            <li className="">
              <a className="" href="https://www.facebook.com/AkkodisBulgaria" target="_blank" rel="noreferrer" title="Join the Akkkodis Facebook page (new window)">
                <span aria-hidden="true" className="iconFacebook" />
               </a>
            </li>
            <li className="">
              <a className="" href="https://www.instagram.com/akkodisbulgaria/" target="_blank" rel="noreferrer" title="Join the Akkkodis Instagram page (new window)">
                <span aria-hidden="true" className="iconInstagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
