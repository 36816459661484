import React from "react";

//  Styling
import '../../../styles/Main.scss';

//  React components
// import { TechSpecsContents } from "./TechSpecsContent";

//  Assets
import TriangleGradientCornerSVG from "../../../../assets/akkodis/triangle-gradient-corner.svg";
import DiagramPNG from "../../../../assets/akkodis/diagram.png";
import DiagramWEBP from "../../../../assets/misc/diagram.webp";
import WorldPNG from "../../../../assets/akkodis/world.png";
import BookingPNG from "../../../../assets/akkodis/booking.png";
import BankingPNG from "../../../../assets/akkodis/banking.png";
import TravelPNG from "../../../../assets/akkodis/travel.png";
import ShoppingPNG from "../../../../assets/akkodis/shopping.png";
import ImaginationPNG from "../../../../assets/akkodis/imagination.png";
import ServicesPNG from "../../../../assets/akkodis/services.png";

//  AWS Assets
import S3SVG from "../../../../assets/AWS/s3.svg";
import LambdaSVG from "../../../../assets/AWS/lambda.svg";
import RDSSVG from "../../../../assets/AWS/rds.svg";
import CognitoSVG from "../../../../assets/AWS/cognito.svg";
import DevToolsSVG from "../../../../assets/AWS/dev_tools.svg";
import CodeCommitSVG from "../../../../assets/AWS/code_commit.svg";
import CodePipelineSVG from "../../../../assets/AWS/code_pipeline.svg";
import CodeBuildSVG from "../../../../assets/AWS/code_build.svg";
import MLSVG from "../../../../assets/AWS/ml.svg";
import LexSVG from "../../../../assets/AWS/lex.svg";
import ComprehendSVG from "../../../../assets/AWS/comprehend.svg";
import TranslateSVG from "../../../../assets/AWS/translate.svg";
import SESSVG from "../../../../assets/AWS/ses.svg";

export default function SectionTechSpecs(props) {
  return (
    <section className="contentSection" id="techSpec">
      <div className="backgrTriangle">
        <img src={TriangleGradientCornerSVG} alt='' />
      </div>
      <div className="text">
        <h2>About the Virtual Assistant</h2>
        <div className="twoColumnsContainer">
          <div className="columnFlex">
            <div className="boxContainer">
              <div className="boxBlock">
                <div className="boxTitle">
                  <img src={DiagramPNG} width={100} height={100} alt="" />
                  <span>Diagram</span>
                </div>
                <div className="gallery">
                  <img src={DiagramWEBP} alt="" />
                </div>
              </div>
            </div>
            <div className="boxContainer">
              <div className="boxBlock">
                <div className="boxTitle">
                  <img src={WorldPNG} width={100} height={100} alt="" />
                  <span>Endless fields for development</span>
                </div>
                <ul className="listStyleBlTxt">
                  <li>
                    <img src={BookingPNG} alt="" />
                    <span>Booking / Reservations</span>
                  </li>
                  <li>
                    <img src={BankingPNG} alt="" />
                    <span>Banking</span>
                  </li>
                  <li>
                    <img src={TravelPNG} alt="" />
                    <span>Traveling / Urban Trnsportation</span>
                  </li>
                  <li>
                    <img src={ShoppingPNG} alt="" />
                    <span>Shopping / Delivery </span>
                  </li>
                  <li>
                    <img src={ImaginationPNG} alt="" />
                    <span>
                      "... and many more"
                      <p>Imagination is the limit</p>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="fullpage" />
          <div className="columnFlex">
            <div className="boxContainer">
              <div className="boxBlock">
                <div className="boxTitle">
                  <img src={ServicesPNG} width={100} height={100} alt="" />
                  <span>Involved AWS Services</span>
                </div>
                <ul className="listStyleBlTxt">
                  <li>
                    <img src={S3SVG} alt="" />
                    <span>
                      S3
                      <p>Amazon Simple Storage Service</p>
                    </span>
                  </li>
                  <li>
                    <img src={LambdaSVG} alt="" />
                    <span>
                      Lambda
                      <p>Code running serverless compute service</p>
                    </span>
                  </li>
                  <li>
                    <img src={RDSSVG} alt="" />
                    <span>
                      RDS
                      <p>AWS Managed SQL database service</p>
                    </span>
                  </li>
                  <li>
                    <img src={CognitoSVG} alt="" />
                    <span>
                      Cognito
                      <p>
                        Adds user sign-up and authentication to mobile and web
                        apps
                      </p>
                    </span>
                  </li>
                  <li>
                    <img src={DevToolsSVG} alt="" />
                    <span>Developer Tools</span>
                  </li>
                  <li className="indent">
                    <img src={CodeCommitSVG} alt="" />
                    <span>
                      CodeCommit
                      <p>Facilitates collaboration on code by teams</p>
                    </span>
                  </li>
                  <li className="indent">
                    <img src={CodePipelineSVG} alt="" />
                    <span>
                      CodePipeline
                      <p>Automating data delivery to apps and infrastructure</p>
                    </span>
                  </li>
                  <li className="indent">
                    <img src={CodeBuildSVG} alt="" />
                    <span>
                      CodeBuild
                      <p>Fully managed service for compiling source code</p>
                    </span>
                  </li>
                  <li>
                    <img src={MLSVG} alt="" />
                    <span>Machine Learning</span>
                  </li>
                  <li className="indent">
                    <img src={LexSVG} alt="" />
                    <span>
                      Lex
                      <p>
                        Voice and text conversational interface for applications
                      </p>
                    </span>
                  </li>
                  <li className="indent">
                    <img src={ComprehendSVG} alt="" />
                    <span>
                      Comprehend
                      <p>Natural-language processing (NLP) service</p>
                    </span>
                  </li>
                  <li className="indent">
                    <img src={TranslateSVG} alt="" />
                    <span>
                      Translate
                      <p>Neural machine translation service</p>
                    </span>
                  </li>
                  <li>
                    <img src={SESSVG} alt="" />
                    <span>
                      SES
                      <p>
                        Email service provider dorm email sending applications
                      </p>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section >
  );
}
