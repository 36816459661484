import React from "react";

//  Styling
import "../../../../styles/WidgetHeader.scss";

//  Assets
import AKKODISLogoLightPNG from '../../../../../assets/akkodis/AKKODIS_Logo_light.png'

export default function WidgetHeader(props) {

  return (
    <div className="chatBoxHeader">
      <img src={AKKODISLogoLightPNG} width="192" height="24" alt="" />
      <p>
        <span>Virtual</span> Assistant
      </p>
    </div>
  );
}
