import React from "react";

//  Styling
import "../../../styles/ButtonContainer.scss";

//  Assets
import CloseChatPNG from '../../../../assets/akkodis/close_chat.png';
import ChatPNG from '../../../../assets/akkodis/chat.png';

export default function ButtonContainer(props) {

  const assistantButtonHandler = () => {
    props.onClick();
  }

  return (
    <div className="btnContainer">
      <button className={ props.isOpened ? "chatButtonOpened" : "chatButtonClosed" }  onClick={assistantButtonHandler} >
        <img src={ props.isOpened ? CloseChatPNG : ChatPNG } alt="" />
        <span>{props.isOpened ? `Close chat`: `vAssistant`}</span>
      </button>
    </div>
  );
}
