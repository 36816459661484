import React, { useContext } from "react";

//  Styling
import "../../styles/Header.scss";

//  Context
import VAssistantContext from "../../../store/context";

//  React components
import Navigation from "./Navigation";

// Assets
import AKKODISLogoDarkPNG from "../../../assets/akkodis/AKKODIS_Logo_dark.png";

export default function Header(props) {
  const {isLoggedIn} = useContext(VAssistantContext);

  return (
    <header>
      <div className="brandWhite">
        <a href="https://www.akkodis.com/" className="logo" target="_blank" rel="noreferrer" alt="AKKODIS" title="AKKODIS" >
          <img src={AKKODISLogoDarkPNG} width="192" height="24" alt="" />
        </a>
        {isLoggedIn && <Navigation />}
      </div>
    </header>
  );
}
