import React from "react";

//  Styling
import "../../styles/Navigation.scss";

//  React components
import SignOutButton from "./SignOutButton";

//  Assets

export default function Navigation() {
  return (
    <React.Fragment>
      {/* <nav>
        <ul>
          <li>
            <a href="#header">Demo</a>
          </li>
          <li>
            <a href="#dcs-cloud">Dcs-Cloud</a>
          </li>
          <li>
            <a href="#techSpec">Tech Info</a>
          </li>
          <li>
            <a href="#cloudSupport">Cloud Support</a>
          </li>
        </ul>
      </nav> */}
      <div className="navRight">
        {/* <div className="contacts">
          <a href="https://www.akkodis.com/en/contact" className="button" target="_blank" rel="noreferrer" >
            Contact
          </a>
        </div> */}
        <SignOutButton />
      </div>
    </React.Fragment>
  );
}
